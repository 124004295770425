<template>
  <div>
    <van-popup v-model="show" position="bottom" :closeable="false">
      <van-picker
        title="选择放映点"
        show-toolbar
        :columns="pointList"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { api } from '@/api'
export default {
  name: 'SelectAreaPoint',
  data() {
    return {
      pointList: [],
      show: false,
      userInfo: {},
      queryParam: {
        pageNum: 1,
        pageSize: 10000,
        regionAreaId: null,
      },
    }
  },
  methods: {
    // 根据当前集体代表用户获取该用户所在村的放映点
    open() {
      console.log('open', this.$store.state.userInfo)
      this.userInfo = this.$store.state.userInfo
      this.queryParam.regionAreaId = this.userInfo.regionAreaId
      this.getPointList()
    },
    getPointList() {
      api.getShowPointsPage(this.queryParam).then((res) => {
        const { success, result } = res
        if (success) {
          this.show = true
          const { records } = result
          this.pointList = records.map((item) => {
            return {
              ...item,
              text: `${item.name}(${item.address})`,
            }
          })
        }
      })
    },
    onConfirm(item) {
      this.$emit('onFinish', item)
      this.show = false
    },
  },
}
</script>

<style></style>
