<template>
  <div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <van-picker
        :title="title"
        :columns="columns"
        show-toolbar
        @cancel="close"
        @confirm="onConfirm"
      >
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    title: {
      type: String,
      default: '选择放映周',
    },
    show: {
      type: Boolean,
      default: false,
    },
    // 最大展示周数
    dayCount: {
      type: Number,
      default: 30,
    },
    //最小开始时间，最小周内任意日期即可
    minDate: {
      type: Date,
      default: () => {
        return new Date()
      },
    },
  },
  data() {
    return {
      columns: [],
      dayList: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      for (let i = 0; i < this.dayCount; i++) {
        const day = this.moment(this.minDate).add(i, 'days')
        this.dayList.push(day)
      }
      this.columns[0] = {
        values: this.dayList.map((t) => {
          return `${t.format('MM月DD日')}`
        }),
        defaultIndex: 0,
      }
      this.columns[1] = {
        values: ['上午', '下午', '晚上'],
        defaultIndex: 1,
      }

      // this.onConfirm(null, 0) // 默认选中第一个
    })
  },
  methods: {
    close() {
      this.$emit('close')
    },
    onConfirm(option, index) {
      // console.log(option, index)
      const selectOption = {
        Date: this.dayList[index[0]],
        Time: this.columns[1].values[index[1]],
      }
      // console.log({ selectOption })
      this.$emit('onConfirm', selectOption)
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-picker-column {
  overflow: hidden !important;
}
</style>
