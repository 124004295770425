<!--集体点播-->
<template>
  <div>
    <van-nav-bar title="集体点播" left-arrow @click-left="goBack" />
    <van-cell-group>
      <van-cell title="期望放映地址" required>
        <template #label>
          <!-- 输入任意文本 -->
          <van-field
            readonly
            v-model="pointName"
            class="box-input"
            placeholder="请填写期望放映地址"
            @click="selectPoint"
            right-icon="location"
          >
            <!-- <template #input>
           {{ pointName }}
          </template> -->
          </van-field>
        </template>
      </van-cell>
      <van-cell title="期望放映日期" required>
        <template #label>
          <!-- 输入任意文本 -->
          <van-field
            readonly
            class="box-input"
            placeholder="请填写放映日期"
            @click="showDate(0)"
            center
            v-model="hopePlayTime"
            right-icon="calendar-o"
          >
          </van-field>
        </template>
      </van-cell>
      <van-cell title="备选期望放映时间" required>
        <template #label>
          <!-- 输入任意文本 -->
          <van-field
            readonly
            class="box-input"
            placeholder="请填写期望放映时间"
            @click="showDate(1)"
            center
            right-icon="calendar-o"
            v-model="alternativePlayTime"
          >
          </van-field>
        </template>
      </van-cell>
      <van-cell title="点播影片" required>
        <template #label>
          <!-- 输入任意文本 -->
          <div class="filmBtn">
            <van-button
              icon="tv-o"
              type="info"
              round
              size="small"
              @click="showFilm = true"
              >选择电影</van-button
            >
            <span
              >已选中 <i class="count">{{ subParam.filmIds.length }}</i
              >部</span
            >
          </div>
        </template>
      </van-cell>
      <van-cell v-for="(film, index) in filmList" :key="index">
        <!-- <template #title>
         <span class="film-mark">
          {{ index+1 }}
         </span><span>{{ film.title }}</span>
        </template> -->
        <template #default>
          <div class="film">
            <div class="film-mark">
              {{ index + 1 }}
            </div>
            <div class="film-title">{{ film.title }}</div>
            <div class="film-del">
              <van-icon name="delete" @click="removeFilm(film)" size="16" />
            </div>
          </div>
        </template>
      </van-cell>
      <!-- <van-cell title="联系人">
        <template #label>
          <van-field
            v-model="groupInfo.representName"
            placeholder="请填写联系人"
            center
            readonly
          />
        </template>
      </van-cell> -->
      <!-- <van-cell title="联系电话">
        <template #label>
          <van-field v-model="groupInfo.representPhone" type="text" readonly />
        </template>
      </van-cell> -->

      <van-cell title="备注" >
        <template #label>
          <van-field
            v-model="subParam.remark"
            type="textarea"
            maxlength="150"
            show-word-limit
            rows="3"
            placeholder="请填写放映要求"
            @click="show.time = true"
            center
            class="box-input"
          />
        </template>
      </van-cell>
    </van-cell-group>
    <div class="playAddress box">
      <p>点播说明</p>
      <div class="desc-box">
        <div>
          1、点播提交后，当地公益放映放映公司将收到您的点播需求。公益放映放映公司将综合各村点播结果和实际情况，进行放映安排。
        </div>
        <br />
        <div>
          2、具体放映安排以放映公告为准，如有疑问，请咨询当地公益放映放映公司。
        </div>
      </div>
    </div>
    <div class="box-button">
      <van-button
        round
        type="info"
        size="large"
        readonly
        style="width: 90%"
        @click="submit"
        color="#3d8bff"
        >提交</van-button
      >
    </div>
    <day-picker
      :show.sync="showDatePicker"
      :min-date="minDate"
      @close="showDatePicker = false"
      @onConfirm="onConfirm"
    ></day-picker>
    <film-picker
      :show="showFilm"
      @close="showFilm = false"
      @onFinished="selectFilm"
      :showPointId="userPoint.id"
    ></film-picker>
    <select-area-point ref="selectAreaPoint" @onFinish="onFinishPoint" />
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { api } from '@/api'
import dayPicker from '@comp/dayPicker/index.vue'
import filmPicker from '@comp/filmPicker/index.vue'
import selectAreaPoint from './module/selectAreaPoint.vue'

export default {
  name: 'groupOnDemand',
  components: {
    dayPicker,
    filmPicker,
    selectAreaPoint,
  },
  data() {
    return {
      // 预选影片列表
      filmList: [],
      showFilm: false,
      showDatePicker: false, // 显示日期选择器
      // 当前设置的日期类型
      showDateType: 0, // 0: 期望放映日期，1: 备选放映日期
      userPoint: {},
      userInfo: null,
      minDate: new Date(),
      address: '',
      filmName: '',
      dataList: {
        movieList: [],
      },
      addressOptions: [],
      groupInfo: {},
      show: {
        date: false,
        addressShow: false,
      },
      subParam: {
        // 期望放映时间
        expectPlayTime: {},
        alternativePlayTime: {},
        expectPlayPlace: '',
        filmIds: [],
        remark: '',
      },
    }
  },
  mounted() {
    // this.userPoint = this.$store.state.userPoint
    this.userInfo = this.$store.state.userInfo
    this.init()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    // 显示放映日期选择器
    showDate(type = 0) {
      this.showDateType = type
      this.showDatePicker = true
    },
    selectPoint() {
      // this.$router.replace({
      //   path: '/ruralFilm/selectPoint',
      //   query: {
      //     form: this.$route.path,
      //     back: true,
      //   },
      // })
      this.$refs.selectAreaPoint.open()
    },
    init() {
      this.getGroupInfo()
      this.filmName = this.getUrlParam('filmTitle')
      this.subParam.filmId = this.getUrlParam('filmId')
      // 设置选择时间为下下周一
      const minDate = this.moment().weekday(7).format('YYYY-MM-DD')
      this.minDate = new Date(minDate)
      // this.getOnDemandRecord()
    },
    // 获取集体信息
    getGroupInfo() {
      api
        .getGroupAccount({})
        .then((res) => {
          this.groupInfo = res
        })
        .then((error) => {
          // console.log(error)
        })
    },
    // 获取URL参数
    getUrlParam(name) {
      return this.$route.query[name] || ''
    },
    onConfirm(data) {
      // const { Date, Time } = data
      this.showDatePicker = false
      if (this.showDateType === 0) {
        console.log('期望放映日期', data)
        this.subParam.expectPlayTime = data
      } else {
        console.log('备选放映日期', data)
        this.subParam.alternativePlayTime = data
      }
    },
    // 选择影片
    selectFilm(item) {
      console.log(this.filmList.indexOf(item))
      // 查询重复添加
      const hasFilm = this.filmList.filter((film) => film.id === item.id)
      if (hasFilm.length > 0) {
        Toast.fail('该影片已添加')
        return
      }

      if (this.filmList.indexOf(item) == -1) {
        this.filmList.push(item)
        Toast.success('添加成功')
        return
      } else {
        Toast('该影片已添加')
        return
      }
    },
    // 删除影片
    removeFilm(item) {
      this.filmList.splice(this.filmList.indexOf(item), 1)
    },
    // 全部选项选择完毕后，会触发 finish 事件
    // onFinish(selectedOptions) {
    // },
    // 选择地址
    onFinishPoint(point) {
      console.log({ point })

      this.userPoint = point
    },
    // 提交点播
    submit() {
      console.log(this.subParam)
      // 验证放映点

      if (!this.userPoint.id) {
        Toast.fail('请选择放映点')
        return
      }
      if (this.subParam.expectPlayPlace == '') {
        this.subParam.expectPlayPlace = this.address
      }
      // 验证提交表单
      if (!this.subParam.expectPlayTime.Date) {
        Toast.fail('请选择期望放映时间')
        return
      }
      if (!this.subParam.alternativePlayTime.Date) {
        Toast.fail('请选择备选放映时间')
        return
      }
      if (!this.filmList.length) {
        Toast.fail('请选择影片')
        return
      }
      // if (!this.subParam.remark.length) {
      //   Toast.fail('请填写备注')
      //   return
      // }
      // morning, afternoon, evening
      const HansToEn = (time) => {
        switch (time) {
          case '上午':
            return 'morning'
          case '下午':
            return 'afternoon'
          case '晚上':
            return 'evening'
        }
      }
      console.log(
        this.subParam.expectPlayTime.Date.format('YYYY-MM-DD'),
        this.subParam.expectPlayPeriod,
      )

      const param = {
        filmIds: this.filmList.map((item) => item.id),
        expectPlayTime: this.subParam.expectPlayTime.Date.format('YYYY-MM-DD'),
        expectPlayPeriod: HansToEn(this.subParam.expectPlayTime.Time),
        alternativePlayTime:
          this.subParam.alternativePlayTime.Date.format('YYYY-MM-DD'),
        alternativePlayPeriod: HansToEn(this.subParam.alternativePlayTime.Time),
        remark: this.subParam.remark,
        showPointId: this.userPoint.id,
        linkman: this.userInfo.username,
        phone: this.userInfo.mobile,
      }
      console.log({ param })
      Dialog.confirm({
        title: '提示',
        message: '是否提交点播',
      })
        .then(() => {
          //执行团体提交
          api
            .submitGroupClick(param)
            .then((res) => {
              console.log(res)
              const { success, message } = res
              if (success) {
                // Toast('提交成功')
                // setTimeout(() => {
                //   this.$router.push('/playRecord?type=group')
                // }, 3000)
                Dialog.alert({
                  title: '提示',
                  message: '提交成功，请等待放映公司安排放映',
                }).then(() => {
                  this.$router.push('/playRecord?type=group')
                })
              } else {
                Toast.fail(message)
              }
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch(() => {
          console.log('取消')
        })
    },
  },
  computed: {
    // 期望放映时间
    hopePlayTime() {
      const date = this.subParam.expectPlayTime
      if (!date.Date) {
        return ''
      }

      return `${this.moment(date.Date).format('MM月DD日')} ${date.Time}`
    },
    // 备选放映时间
    alternativePlayTime() {
      const date = this.subParam.alternativePlayTime
      if (!date.Date) {
        return ''
      }

      return `${this.moment(date.Date).format('MM月DD日')} ${date.Time}`
    },
    pointName() {
      // {{ userPoint.name || '' }} - {{ userPoint.address || '' }}
      if (this.userPoint.name) {
        return `${this.userPoint.name} - ${this.userPoint.address}`
      }
      return ''
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-cell {
  line-height: normal;
  background-color: #f6f7f9;
}
.box-button {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 0;
}
.box {
  padding: 10px 10px;
  //width: 100%;
  min-height: 80px;
  margin-bottom: 100px;
  &-input {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e8e9ec;
    min-height: 56px;
    padding: 20px;
  }
}
.desc-box {
  font-size: 14px;
  padding: 10px;
  color: darkgray;
}
.filmBtn {
  width: 45vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .count {
    color: @color-primary;
    font-style: normal;
    font-weight: bold;
    padding: 0 5px;
  }
}
.film {
  display: flex;
  justify-content: start;
  align-items: center;
  &-mark {
    border: 1px solid @color-primary;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @color-primary;
    margin: 12px;
  }
  &-title {
    padding: 12px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e8e9ec;
    width: 70vw;
    color: #686b73;
  }
  &-del {
    color: @color-error;
    margin-left: 12px;
  }
}
</style>
